module.exports = [{
      plugin: require('C:/Users/Leo/Documents/Programowanie/ARPI/arpi.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-41638275-1"},
    },{
      plugin: require('C:/Users/Leo/Documents/Programowanie/ARPI/arpi.com/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\layouts\\index.js"},
    },{
      plugin: require('C:/Users/Leo/Documents/Programowanie/ARPI/arpi.com/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Leo/Documents/Programowanie/ARPI/arpi.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Leo/Documents/Programowanie/ARPI/arpi.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
