// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-template-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\templates\\CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-post-template-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\templates\\PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-page-template-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\templates\\PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-no-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\kontakt-no.js" /* webpackChunkName: "component---src-pages-kontakt-no-js" */),
  "component---src-pages-kontakt-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-menu-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-nb-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\nb.js" /* webpackChunkName: "component---src-pages-nb-js" */),
  "component---src-pages-pl-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\pl.js" /* webpackChunkName: "component---src-pages-pl-js" */),
  "component---src-pages-services-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-success-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tjenester-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\tjenester.js" /* webpackChunkName: "component---src-pages-tjenester-js" */),
  "component---src-pages-uslugi-js": () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\src\\pages\\uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */)
}

exports.data = () => import("C:\\Users\\Leo\\Documents\\Programowanie\\ARPI\\arpi.com\\.cache\\data.json")

